import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import getC360PendingOrdersAndRXTicketInfosMockResp from '../../../assets/json/apiMockResp/getC360PendingOrdersAndRXTicketInfos.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 2000;
const MOCK_TIME_BOUND_MAX = 5000;

export function* getC360PendingOrdersAndRXTicketInfos(
  accountNumber: string | undefined,
  orderNumberOrTicketNumber: string | undefined,
  state: string | undefined,
  isAuth: boolean
): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: getC360PendingOrdersAndRXTicketInfosMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  let API_ENDPOINT = apiEndpts.WMT_GET_WMT_APPOINTMENTS;
  if (accountNumber) {
    API_ENDPOINT = isAuth
      ? apiEndpts.AUTH_WMT_GET_TICKETINFOS
      : apiEndpts.WMT_GET_WMT_APPOINTMENTS;
    return yield call(
      axios.get,
      `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?accountNumber=${accountNumber}`,
      {
        withCredentials: true,
        headers: {
          ApiKey: encryptedApiKey,
        },
        /* timeout: 60000, */
      }
    );
  }
  API_ENDPOINT = isAuth
    ? apiEndpts.AUTH_WMT_GET_TICKETINFOS
    : apiEndpts.WMT_GET_C360_PENDINGORDERS_AND_RX_TICKETINFOS;
  return yield call(
    axios.get,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?orderNumberOrTicketNumber=${orderNumberOrTicketNumber}&state=null`,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
