export const GET_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER =
  'GET_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER';
export const GET_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER =
  'GET_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER';
export const GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER =
  'GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER';
export const GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE =
  'GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE';
export const GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER =
  'GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER';
export const GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER =
  'GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER';
export const GET_WMTLANDINGMODULE_THROTTLED = 'GET_WMTLANDINGMODULE_THROTTLED';
export const GET_WMTLANDINGMODULE_SUBMITPENDING =
  'GET_WMTLANDINGMODULE_SUBMITPENDING';
export const GET_WMTLANDINGMODULE_CURRSTATE = 'GET_WMTLANDINGMODULE_CURRSTATE';
export const GET_WMTLANDINGMODULE_PREVSTATE = 'GET_WMTLANDINGMODULE_PREVSTATE';
export const GET_WMTLANDINGMODULE_QUERYPARAMSUSED =
  'GET_WMTLANDINGMODULE_QUERYPARAMSUSED';
export const GET_WMTLANDINGMODULE_HIDERESULTS =
  'GET_WMTLANDINGMODULE_HIDERESULTS';
export const MUTATE_WMTLANDINGMODULE_QUERYPARAMSUSED =
  'MUTATE_WMTLANDINGMODULE_QUERYPARAMSUSED';
export const MUTATE_WMTLANDINGMODULE_HIDERESULTS =
  'MUTATE_WMTLANDINGMODULE_HIDERESULTS';
export const MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER =
  'MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER';
export const MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER =
  'MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER';
export const MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER =
  'MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER';
export const MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE =
  'MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE';
export const MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER =
  'MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER';
export const MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER =
  'MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER';
export const MUTATE_WMTLANDINGMODULE_THROTTLED =
  'MUTATE_WMTLANDINGMODULE_THROTTLED';
export const MUTATE_WMTLANDINGMODULE_SUBMITPENDING =
  'MUTATE_WMTLANDINGMODULE_SUBMITPENDING';
export const MUTATE_WMTLANDINGMODULE_CURRSTATE =
  'MUTATE_WMTLANDINGMODULE_CURRSTATE';
export const MUTATE_WMTLANDINGMODULE_PREVSTATE =
  'MUTATE_WMTLANDINGMODULE_PREVSTATE';
export const ACTION_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER =
  'ACTION_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER';
export const ACTION_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER =
  'ACTION_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER';
export const ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER =
  'ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER';
export const ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE =
  'ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE';
export const ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER =
  'ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER';
export const ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER =
  'ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER';
export const ACTION_WMTLANDINGMODULE_THROTTLED =
  'ACTION_WMTLANDINGMODULE_THROTTLED';
export const ACTION_WMTLANDINGMODULE_SUBMITPENDING =
  'ACTION_WMTLANDINGMODULE_SUBMITPENDING';
export const ACTION_WMTLANDINGMODULE_QUERYPARAMSUSED =
  'ACTION_WMTLANDINGMODULE_QUERYPARAMSUSED';
export const ACTION_WMTLANDINGMODULE_HIDERESULTS =
  'ACTION_WMTLANDINGMODULE_HIDERESULTS';
export const WMTLANDINGMODULE = 'wmtLandingModule';
export const NS_GET_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER}`;
export const NS_GET_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER}`;
export const NS_GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER}`;
export const NS_GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE}`;
export const NS_GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER}`;
export const NS_GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER}`;
export const NS_GET_WMTLANDINGMODULE_THROTTLED = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_THROTTLED}`;
export const NS_GET_WMTLANDINGMODULE_SUBMITPENDING = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_SUBMITPENDING}`;
export const NS_GET_WMTLANDINGMODULE_CURRSTATE = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_CURRSTATE}`;
export const NS_GET_WMTLANDINGMODULE_PREVSTATE = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_PREVSTATE}`;
export const NS_GET_WMTLANDINGMODULE_QUERYPARAMSUSED = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_QUERYPARAMSUSED}`;
export const NS_GET_WMTLANDINGMODULE_HIDERESULTS = `${WMTLANDINGMODULE}/${GET_WMTLANDINGMODULE_HIDERESULTS}`;
export const NS_MUTATE_WMTLANDINGMODULE_QUERYPARAMSUSED = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_QUERYPARAMSUSED}`;
export const NS_MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER}`;
export const NS_MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER}`;
export const NS_MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER}`;
export const NS_MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE}`;
export const NS_MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER}`;
export const NS_MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER}`;
export const NS_MUTATE_WMTLANDINGMODULE_THROTTLED = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_THROTTLED}`;
export const NS_MUTATE_WMTLANDINGMODULE_SUBMITPENDING = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_SUBMITPENDING}`;
export const NS_MUTATE_WMTLANDINGMODULE_CURRSTATE = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_CURRSTATE}`;
export const NS_MUTATE_WMTLANDINGMODULE_PREVSTATE = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_PREVSTATE}`;
export const NS_MUTATE_WMTLANDINGMODULE_HIDERESULTS = `${WMTLANDINGMODULE}/${MUTATE_WMTLANDINGMODULE_HIDERESULTS}`;
export const NS_ACTION_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER}`;
export const NS_ACTION_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER}`;
export const NS_ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER}`;
export const NS_ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE}`;
export const NS_ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER}`;
export const NS_ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER}`;
export const NS_ACTION_WMTLANDINGMODULE_THROTTLED = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_THROTTLED}`;
export const NS_ACTION_WMTLANDINGMODULE_SUBMITPENDING = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_SUBMITPENDING}`;
export const NS_ACTION_WMTLANDINGMODULE_QUERYPARAMSUSED = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_QUERYPARAMSUSED}`;
export const NS_ACTION_WMTLANDINGMODULE_HIDERESULTS = `${WMTLANDINGMODULE}/${ACTION_WMTLANDINGMODULE_HIDERESULTS}`;
