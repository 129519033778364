/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { useVuelidateRulesSingleton } from '@/common/utils/vuelidateRulesSingleton';
import {
  C360Account,
  GetC360AcctsParallelMap,
  GetC360AcctsState,
} from '@/store/modules/getC360AcctsParallelModule/types';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGCAP from '@/store/modules/getC360AcctsParallelModule/treekeys';
import * as tkA from '@/store/modules/auth/treekeys';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { getC360AcctsParallel } from '../../watchGetC360AcctsParallel';

class QueryParamsWMTLHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getAcctInMap = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNum: string
  ) =>
    Object.values(getC360AcctsParallelMap)
      ?.flatMap(
        (getC360AcctsState: GetC360AcctsState) =>
          getC360AcctsState?.c360AccountList?.c360AccountList?.accounts
      )
      ?.find(
        (c360Account: C360Account | undefined) =>
          c360Account?.accountNumber === acctNum
      );

  getActionBasedOnRules = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNumOrEmail: string,
    isAuth: boolean
  ): HandleQueryParamsAction => {
    const vuelidateRules = useVuelidateRulesSingleton();
    if (
      !acctNumOrEmail ||
      !vuelidateRules.isSAMAcctNumRegexMatch(acctNumOrEmail)
    ) {
      return HandleQueryParamsAction.GOTO_WMTLANDING;
    }
    const acctInMap = this.getAcctInMap(
      getC360AcctsParallelMap,
      acctNumOrEmail
    );
    if (
      !acctInMap &&
      Object.keys(getC360AcctsParallelMap?.[acctNumOrEmail]).length &&
      (!getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ?.c360AccountList?.accounts?.length ||
        vuelidateRules.isNoAcctsFoundError(
          getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ) ||
        vuelidateRules.isAcctDeactivatedError(
          getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ) ||
        !vuelidateRules.hasAcctsWithoutBadStatuses(
          getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ))
    ) {
      if (isAuth) {
        return HandleQueryParamsAction.GOTO_WMTRESULTS;
      }
      return HandleQueryParamsAction.GOTO_WMTLANDING;
    }
    return HandleQueryParamsAction.PENDING;
  };

  getActionBasedOnRulesOrderNumAndState = (
    orderNum: string,
    state: string
  ): HandleQueryParamsAction => {
    const vuelidateRules = useVuelidateRulesSingleton();
    if (
      !vuelidateRules.isSAMOrderNumRegexMatch(orderNum) ||
      !vuelidateRules.isSAMStateValid(state)
    ) {
      return HandleQueryParamsAction.GOTO_WMTLANDING;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const orderNum: string = queryParams?.orderNumberOrTicketNumber || '';
    const state: string = queryParams?.state || '';
    const getC360AcctsParallelMap: GetC360AcctsParallelMap = yield select(
      (s) => s?.[tkGCAP.GETC360ACCTSPARALLELMODULE]?.getC360AcctsParallelMap
    );
    const isAuth = yield select((s) => s?.[tkA.AUTHMODULE]?.authenticated);
    if (
      acctNumOrEmail &&
      !this.getAcctInMap(getC360AcctsParallelMap, acctNumOrEmail)
    ) {
      yield call(getC360AcctsParallel, { payload: acctNumOrEmail });
    }
    if (orderNum || state) {
      response.handleQueryParamsAction =
        this.getActionBasedOnRulesOrderNumAndState(orderNum, state);
    } else {
      response.handleQueryParamsAction = this.getActionBasedOnRules(
        getC360AcctsParallelMap,
        acctNumOrEmail,
        isAuth
      );
    }
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsWMTLHandler;

export const useQueryParamsWMTLHandler = (): QueryParamsWMTLHandler => {
  if (!qp) {
    qp = new QueryParamsWMTLHandler();
  }
  return qp;
};
