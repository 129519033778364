
import { DSSHeader, DSSFooter, DSSHeaderSignedIn } from 'dss-shared-components';
import { computed, watch, defineComponent, ref } from 'vue';
import * as tkA from '@/store/modules/auth/treekeys';
import * as tkPMSM from '@/store/modules/performMobileSSOModule/treekeys';
import * as tkGCAP from '@/store/modules/getC360AcctsParallelModule/treekeys';
import * as tkGCARTP from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule/treekeys';
import * as tkPS from '@/store/modules/progressspinner/treekeys';
import * as tkG3EUP from '@/store/modules/get34SEcomUserProfileModule/treekeys';
import { _34SEcomUserProfileResponse } from '@/store/modules/get34SEcomUserProfileModule/types';

import { useGtmUtilsSingleton } from '@/common/utils/gtmUtilsSingleton';
import { useCookieUtilsSingleton } from '@/common/utils/cookieUtilsSingleton';
import { useNSProtectedStore } from './store';

import {
  VUE_APP_CUSTCARE_LOGOUT_URL,
  VUE_APP_DOTCOMHOST,
  VUE_APP_LUMENHOST,
  VUE_APP_BRIGHTSPEED_TOGGLE,
  VUE_APP_BRIGHTSPEED_URL,
  VUE_APP_DH_HOST,
  VUE_APP_DSSP_HOST,
} from './common/envVars';

export default defineComponent({
  name: 'App',
  components: {
    DSSHeader,
    DSSHeaderSignedIn,
    DSSFooter,
  },
  setup() {
    const store = useNSProtectedStore(tkA.AUTHMODULE);

    const store34s = useNSProtectedStore(tkG3EUP.GET34SECOMUSERPROFILEMODULE);
    const pmsmStore = useNSProtectedStore(tkPMSM.GETPERFORMMOBILESSOMODULE);
    const getAccountsResp = ref<_34SEcomUserProfileResponse>();
    // const authAccounts = ref<AccountNumberInfo[]>();

    let companyOwnerId = '';
    const gtm = useGtmUtilsSingleton();
    const cookieUtils = useCookieUtilsSingleton();

    const getCompanyOwnerIdValue = (
      ciCOIDValue: any,
      pendingOrderCOIDvalue: any
    ) => {
      if (ciCOIDValue !== null && ciCOIDValue !== undefined) {
        companyOwnerId = ciCOIDValue;
      } else if (
        pendingOrderCOIDvalue.value !== null &&
        pendingOrderCOIDvalue.value !== undefined
      ) {
        companyOwnerId = pendingOrderCOIDvalue.value;
      }
      if (companyOwnerId === '4') {
        gtm.trackGTMEvent({
          company: 'brightspeed',
        });
      }
      return companyOwnerId;
    };
    const authenticated = computed(
      () =>
        store.getters[tkA.NS_GET_AUTH_AUTHENTICATED] ||
        pmsmStore.getters[tkPMSM.NS_GET_PERFORMMOBILESSOMODULE_AUTHENTICATED]
    );
    const onChangeAccount = (input: any) => {
      console.log(input);
    };
    const onSignOut = async () => {
      await cookieUtils.clearAllCookies().then(() => {
        console.log(`Calling the signout function`);
        window.open(`${VUE_APP_CUSTCARE_LOGOUT_URL}`, '_self');
      });
    };

    watch(
      () =>
        store.getters[tkGCAP.NS_GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID],
      (newVal: any) => {
        companyOwnerId = newVal;
      },
      { immediate: true }
    );

    watch(
      () =>
        store34s.getters[
          tkG3EUP.NS_GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE
        ],
      (newVal: _34SEcomUserProfileResponse) => {
        if (newVal) {
          getAccountsResp.value = newVal;
        }
      }
    );

    return {
      loading: computed(() => store.getters[tkA.NS_GET_AUTH_PENDING]),
      circularSpinner: computed(
        () => store.getters[tkPS.NS_GET_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR]
      ),
      authenticated,
      signOutUrl: VUE_APP_CUSTCARE_LOGOUT_URL,
      companyOwnerId: computed(() =>
        VUE_APP_BRIGHTSPEED_TOGGLE === 'true'
          ? getCompanyOwnerIdValue(
              store.getters[
                tkGCAP.NS_GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID
              ],
              store.getters[
                tkGCARTP
                  .NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETCOMPANYOWNERID
              ]
            )
          : '1'
      ),
      getAccountsResp,
      onChangeAccount,
      onSignOut,
      dotComHost: VUE_APP_DOTCOMHOST,
      myCtlHost: VUE_APP_DH_HOST,
      lumenHost: VUE_APP_LUMENHOST,
      brspdUrl: VUE_APP_BRIGHTSPEED_URL,
      dhHost: VUE_APP_DH_HOST,
      dsspHost: VUE_APP_DSSP_HOST,
    };
  },
});
