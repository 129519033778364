/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/getC360PendingOrdersAndRXTicketInfosParallelModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import { getC360PendingOrdersAndRXTicketInfos as getC360PendingOrdersAndRXTicketInfosApi } from './api/getC360PendingOrdersAndRXTicketInfos';
import { GetC360PendingOrdersAndRXTicketInfosParallelMap } from '../modules/getC360PendingOrdersAndRXTicketInfosParallelModule/types';

const shouldCall = (
  gcpartMap: GetC360PendingOrdersAndRXTicketInfosParallelMap,
  num: string
) =>
  num &&
  gcpartMap &&
  !gcpartMap[num]?.pending &&
  (!gcpartMap[num]?.c360PendingOrdersAndRXTicketInfos ||
    !Object.keys(gcpartMap[num]?.c360PendingOrdersAndRXTicketInfos).length);

const accountOrderCheck = (
  gMap: GetC360PendingOrdersAndRXTicketInfosParallelMap,
  nm: string,
  getC360Payload: any
) =>
  gMap[nm] === undefined ||
  (gMap[nm] !== undefined &&
    gMap[nm]?.c360PendingOrdersAndRXTicketInfos?.state === null &&
    getC360Payload?.payload?.state !== '') ||
  (gMap[nm] !== undefined &&
    gMap[nm]?.c360PendingOrdersAndRXTicketInfos?.state !== null &&
    getC360Payload?.payload?.state === '');

export function* getC360PendingOrdersAndRXTicketInfosParallel(
  getC360PendingOrdersAndRXTicketInfosPayload: any
): any {
  const num: string =
    getC360PendingOrdersAndRXTicketInfosPayload?.payload?.accountNumber?.replaceAll(
      '*',
      ''
    ) ||
    getC360PendingOrdersAndRXTicketInfosPayload?.payload
      ?.orderNumberOrTicketNumber;
  const gcpartMap: GetC360PendingOrdersAndRXTicketInfosParallelMap =
    yield select(
      (s) =>
        s?.[tk.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE]
          ?.getC360PendingOrdersAndRXTicketInfosParallelMap
    );
  const shouldThrottle: boolean = yield select(
    (s) =>
      s?.[tk.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE]?.shouldThrottle
  );

  if (
    ((shouldCall(gcpartMap, num) && !shouldThrottle) ||
      accountOrderCheck(
        gcpartMap,
        num,
        getC360PendingOrdersAndRXTicketInfosPayload
      )) &&
    getC360PendingOrdersAndRXTicketInfosPayload?.payload?.companyOwnerID !== '4'
  ) {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: true,
    });
    yield put({
      type: tk.NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING,
      key: num,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) =>
        s?.[tk.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE]
          ?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }
      const resp = yield call(
        getC360PendingOrdersAndRXTicketInfosApi,
        getC360PendingOrdersAndRXTicketInfosPayload?.payload?.accountNumber,
        getC360PendingOrdersAndRXTicketInfosPayload?.payload
          ?.orderNumberOrTicketNumber,
        getC360PendingOrdersAndRXTicketInfosPayload?.payload?.state,
        isAuth
      );
      if (resp?.data) {
        yield put({
          type: tk.NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS,
          c360PendingOrdersAndRXTicketInfos: resp.data,
          key: num,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR,
          c360PendingOrdersAndRXTicketInfosError: resp,
          key: num,
        });
      }
    } catch (err) {
      if (gcpartMap[num]) {
        yield put({
          type: tk.NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR,
          c360PendingOrdersAndRXTicketInfosError: err,
          key: num,
        });
      }
    } finally {
      yield put({
        type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
        progressSpinnerAppear: false,
      });
      if (yield cancelled()) {
        if (gcpartMap[num]) {
          yield put({
            type: tk.NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR,
            c360PendingOrdersAndRXTicketInfosError: 'SAGA_CANCELLED',
            key: num,
          });
        }
      }
    }
  }
}

export function* watchGetC360PendingOrdersAndRXTicketInfosParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getC360PendingOrdersAndRXTicketInfosParallel);
}

export default watchGetC360PendingOrdersAndRXTicketInfosParallel;
